import React from "react";
import "./App.css";
import Layout from "./views/pages/Layout";

function App() {


  return (
    <div>
      <Layout />
    </div>
  );
}

export default App;