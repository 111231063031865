import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer'
import 'react-toastify/dist/ReactToastify.css';
import Rating from 'react-rating'
import { motion } from 'framer-motion';
import axios from "axios";
import Skeleton from '../../components/utils/Skeleton';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { animateScroll as scrollTo } from 'react-scroll';


const PreBuildWebsite = () => {

    const [templates, setTemplates] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        axios.get('https://admin.ebitans.com/api/v1/templates')
            .then(res => {
                // console.log(res, "res");
                setTemplates(res?.data?.templates)
                setLoad(false)
            })
    }, [])

    useEffect(() => {
        scrollTo.scrollToTop({
          duration: 10000,
          smooth: 'easeOutQuart',
        });
      }, []);

    if (load) {
        return <div className='text-center text-4xl font-bold text-gray-400 h-screen flex justify-center items-center'><Skeleton /></div>
    }

    return (
        <div>
            <div className="sm:h-[40vh] h-[15vh] bg-center bg-[length:100%_100%] flex flex-col gap-4 items-center sm:mt-16 mt-14 justify-center bg-[url('https://ebitans.com/Image/cover/4.png')] bg-no-repeat ">
                <div className="">
                    <h1 className='text-2xl sm:text-4xl font-bold my-1 text-center text-[#393b39]'>All Theme</h1>
                    <h1 className='text-2xl font-thin sm:font-bold sm:text-4xl md:text-5xl lg:text-7xl my-1 text-center text-white'>Explore What We Offer</h1>
                </div>
            </div>

            <div className='xl:max-w-[1200px] px-5 xl:px-0 mx-auto my-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-x-10 sm:gap-y-10 gap-4'>
                {

                    templates.map((item) =>
                        <Template key={item.id} item={item} />
                    )
                }
            </div>

            <Footer />
        </div>
    )
}

export default PreBuildWebsite


const Template = ({ item }) => {

    const themeImg = 'https://admin.ebitans.com/assets/images/template/'

    return (
        <a href={item?.liveurl} target="_blank" rel="noopener noreferrer"><div className='shadow-md rounded-md p-2 bg-gray-100 border-[2px] border-black'>

            <div className='relative border-1 group bg-slate-900 bg-blend-multiply' >
                <div className='w-full h-[300px] pb-2 bg-gray-50' >
                    <img className='rounded-t h-[300px] w-full group-hover:bg-blend-darken group-hover:opacity-10 transition duration-150 ease-out group-hover:ease-in' src={themeImg + item?.feature_image} alt="" />
                    <div className='absolute inset-0 text-sm group-hover:flex justify-center items-center hidden cursor-pointer'>
                        <h2 className=' text-black font-semibold hover:pr-4 pr-0 duration-500 hover:tracking-widest' >View Demo</h2>
                        <BsArrowRight className=" flex-shrink-3 h-6 w-6 text-black  duration-500" />
                    </div>
                </div>
            </div>
            <motion.h1 whileHover={{
                y: -3,
                transition: { duration: 0.5 },
            }}
                exit={{
                    y: 0,
                    transition: { duration: 0.5 }
                }}
                className=' bg-gray-100 mt-1 mb-[1px] px-2 sm:text-xl text-lg font-bold text-black hover:text-[#f1593a] cursor-pointer'>{item.name}
            </motion.h1>
            <h2 className=' bg-gray-100 mb-2 px-2 text-xs font-bold text-black'> Category <span className='font-normal'>{item.category} </span></h2>

            <div>
                <div className='grid grid-cols-2 gap-2'>
                    <div>
                        <h1 className=' bg-gray-100 mt-1 mb-1 px-2 sm:text-xl text-lg font-bold text-[#f1593a]'>{item?.is_premium === 'Paid' ? 'BDT:' : 'FREE'} {item.price === 0 ? '' : item.price} </h1>

                        <div class="flex items-center px-2">
                            <Rating className="text-warning sm:text-sm text-xs"
                                initialRating={item.review}
                                emptySymbol={<AiOutlineStar color='#F1593A' />}
                                fullSymbol={<AiFillStar color='#F1593A' />}
                                readonly></Rating>
                            <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">({item.reviewer})</p>
                        </div>

                        <h2 className=' bg-gray-100 mt-1 mb-2 px-2 text-xs font-normal text-gray-600 hover:text-orange-500 cursor-pointer'> {item.downlad} Downloded</h2>
                    </div>
                    <div className='flex items-end justify-end pr-2 pb-2'>
                        <a href={item.link} target='_blank' rel="noopener noreferrer" className='' ><p className='bg-[#fff] text-[#f1593a] font-semibold text-center rounded-md py-2 drop-shadow-lg shadow-md hover:text-[#fff] border-[#f1593a] hover:border-[#f1593a] hover:bg-[#f1593a] border-2 outline-0 duration-500 px-6'>
                            View Demo</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </a>
    )
}