import React from 'react'
import { IoIosArrowDroprightCircle } from 'react-icons/io'

const Why = () => {
    return (
        <div className='bg-black px-5 xl:px-0'>
            <div className='bg-[#090909] xl:max-w-[1200px] mx-auto p-10 -mt-20 relative z-[1]'>
                <h1 className='text-white  md:text-5xl text-3xl font-bold'>Why Choose WAVE BOX?</h1>
                <div className='text-[#717171] font-bold mt-10'>
                    <p className='mb-5'> <p className='text-[#b4b1b1] font-bold'>Customized Product Design & Development:</p> We don’t believe in one-size-fits-all solutions. Our expert designers craft unique, tailor-made product that perfectly align with your brand and business goals, ensuring you stand out in a crowded market.
                    </p>

                    <p className='mb-5'>
                    <p className='text-[#b4b1b1] font-bold'> Exceptional Quality at Competitive Prices: </p>Quality shouldn’t come with a hefty price tag. AT WAVE BOX, we deliver top-tier products that combine innovative design, robust functionality, and affordability, giving you the best value for your investment.
                    </p>

                    <p className='mb-5'>
                    <p className='text-[#b4b1b1] font-bold'> Unparalleled After-Sales Service:  </p>Our commitment to your success doesn’t end at launch. We offer ongoing support, helping you manage, optimize, and grow your digital presence. 
                    </p>

                    <p className='mb-5'>AT WAVE BOX, we’re not just focused on creating a website, mobile app, or software that meets your needs today—we’re dedicated to being your long-term partner.  </p>
                </div>

                <div className="flex items-center gap-5 px-10 py-3 border rounded-full w-max mt-8 hover:scale-x-105 duration-700">
                    <IoIosArrowDroprightCircle className="text-white text-3xl" />
                    <p className="tracking-[4px] text-white font-semibold text-xs">LEARN MORE</p>
                </div>
            </div>
        </div>
    )
}

export default Why